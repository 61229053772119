import React, { useEffect, useRef } from 'react'
import './about.css'
import { useInView } from 'react-intersection-observer'
import { inView, motion, useAnimation } from "framer-motion"
import abtImg from '../assets/about-img.png'



const About = () => {
  // const {ref, inView} = useInView();
  // const animation = useAnimation();
  // const animation2 = useAnimation();
  // useEffect(()=>{
  //   if(inView){
  //       animation.start("visible");
  //       animation2.start("visible");
  //   }
  //   if(!inView){
  //     animation.start("hidden");
  //     animation2.start("hidden");
  //   }

  // }, [inView]);

//   const ref = useRef(null);
// const isInView = useInView(ref, {once: true})
// const mainControls = useAnimation();
// const slideControls = useAnimation();

// useEffect(() =>{
//   if (isInView){
//     mainControls.start("visible");
//     slideControls.start("visible");
//   }
// }, [isInView])
  return (
    <div  className='about-container' id='about'>
      <motion.div
      initial={{ opacity: 0, y:"150px", scale: 0.8 }}
      whileInView={{ opacity: 1,y:"0px", scale: 1 }}
      transition={{ duration: 1, type: "spring", delay: 0.2 }}
        className="top-about">
        <div className="yellow-box"></div>
        <h1 className='about-header'>ABOUT US</h1>
      </motion.div>
      <div className="bottom-about">
        <motion.div
       initial={{ opacity: 0, y:"150px", scale: 0.8 }}
       whileInView={{ opacity: 1,y:"0px", scale: 1 }}
       transition={{ duration: 1, type: "spring", delay: 0.2 }}
              className="about-details">
          <h3 className="about-title">Our Story</h3>
          <p className="about-text">Welcome To Harkani, interior design and Finishing company! We Specialize in creating beautiful and functional spaces that reflect our clients' unique style and needs. Out team of experienced designers and craftsman work together to bring our clients visions to life We pride ourselves on our attention to details and commitment to customer satisfaction . Most importantly we respect our client's budget and established timeline. Let us help you transform your space into your dream home.</p>
        </motion.div>
        <motion.div 
        initial={{ opacity: 0, y:"150px", scale: 0.8 }}
        whileInView={{ opacity: 1,y:"0px", scale: 1 }}
        transition={{ duration: 1, type: "spring", delay: 0.2 }}
        className="about-img">
          <img src={abtImg} alt="" />
        </motion.div>
      </div>
    </div>
  )
}

export default About