import React from 'react'
import blog1 from '../assets/blog1.jpg'
import blog2 from '../assets/blog2.jpg'
import './blogs.css'
import { motion } from 'framer-motion'
const Blogs = () => {
  return (
    <div className='blogs-container'>
      <motion.h1 initial={{ opacity: 0, x:"150px", scale: 0.5 }}
                whileInView={{ opacity: 1, x:"0px", scale:1 }}
                transition={{ duration: 0.5, type: "spring", delay: 0.2 }} className='blogs-header'>Harkani <span>Blogs</span></motion.h1>
       <motion.div  
       initial={{ opacity: 0, x:"150px", scale: 0.5 }}
       whileInView={{ opacity: 1, x:"0px", scale:1 }}
       transition={{ duration: 0.5, type: "spring", delay: 0.3 }}className="blog">
        <div className="top-blog">
          <img src={blog1} alt="" />
          <div className="timestamp">
                <p className='small-text-blog'>July 15, 2023</p>
                <p className='small-text-blog'>by Kena Adam</p>
          </div>
        </div>
        <div className="bottom-blog">
            <h1 className="blog-title">Transform Your Space: Top Interior Design Trends of 2023</h1>
            <p className='blog-text'>Welcome to our blog, where we explore the latest and greatest trends in interior design to help you create a home that reflects your unique style and personality. In 2023, interior design is all about embracing individuality, sustainability, and the perfect blend of modern and timeless elements. Join us as we dive into the top trends that will elevate your living spaces to new heights.</p>
            <button className="read-more-blog">Read more</button>
        </div>  
       </motion.div> 
       <motion.div
       initial={{ opacity: 0, x:"150px", scale: 0.5 }}
       whileInView={{ opacity: 1, x:"0px", scale:1 }}
       transition={{ duration: 0.5, type: "spring", delay: 0.3 }} className="blog">
        <div className="top-blog">
          <img src={blog2} alt="" />
          <div className="timestamp">
                <p className='small-text-blog'>September 04, 2023</p>
                <p className='small-text-blog'>by Capital Habtamu</p>
          </div>
        </div>
        <div className="bottom-blog">
            <h1 className="blog-title">Bringing Spaces to Life: Unveiling the Magic of Interior Design</h1>
            <p className='blog-text'>Embracing Timeless Elegance: Classic Design Elements
                        Dive into the world of timeless elegance as we explore classic design elements that stand the test of time. From intricate moldings to regal color palettes, discover how incorporating traditional elements can add sophistication and charm to any space.</p>
            <button className="read-more-blog">Read more</button>
        </div>  
       </motion.div> 
    </div>
  )
}

export default Blogs;