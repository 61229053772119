import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import './portfolio.css'
import office1 from '../assets/harkani interiors.png'
import office2 from '../assets/hewa technologies.png'
import office3 from '../assets/kesiko.png'
import residence1 from '../assets/abdi condo.png'
import residence2 from '../assets/biruk condo.png'
import residence3 from '../assets/edosa.png'
import residence4 from '../assets/geleta condo.png'
import residence5 from '../assets/kito.png'
import residence6 from '../assets/mati.png'
import residence7 from '../assets/sisay.png'
import restaurant1 from '../assets/sington coffee.png'
import restaurant2 from '../assets/sami bar.png'
import shop from '../assets/a+.png'
import shop1 from '../assets/cosmetics shop.png'
import shop2 from '../assets/gold shop.png'
import shop3 from '../assets/hair salon.png'
import shop4 from '../assets/i-link.png'
import shop5 from '../assets/ip-tech.png'
import shop6 from '../assets/ip-tech2.png'
import shop7 from '../assets/kids shop.png'
import shop8 from '../assets/milki tech.png'
import shop17 from '../assets/nano aberus ict.png'
import shop9 from '../assets/nano get ict.png'
import shop10 from '../assets/phone shop.png'
import shop11 from '../assets/sara velo.png'
import shop12 from '../assets/soli cosmetics.png'
import shop13 from '../assets/ultra electronics.png'
import shop14 from '../assets/watch&cosmo.png'
import shop15 from '../assets/welldone ceramics.png'
import shop16 from '../assets/yeshi mens fashion.png'
import studio from '../assets/ieya.png'
import studio1 from '../assets/wirtu media.png'

const Portfolio = () => {
  
  const tabData = [
    {
      title: 'Office',
      content: [
        { id: 1, category:'Gazebo Square, GAzebo Building', title: 'HARKANI INTERIORS',img: office1 },
        { id: 2, category:'Bole medhanialem, Bass Addis building', title: 'HEWA TECHNOLOGIES',img: office2 },
        { id: 3, category:'Lideta', title: 'KESIKO GENERAL TRADING',img: office3 },
        { id: 3, category:'Meskel Square, in front of Flamingo', title: 'MINING OFFICE',img: shop16 },
      ],
      
    },
    {
      title: 'Residence',
      content: [
        { id: 4, category:'bulbula, 20/80 Condominum', title: '3 bed Appartment',img: residence1 },
        { id: 7, category:'bulbula, 20/80 Condominum', title: '2 bed Appartment',img: residence4 },
        { id: 8, category:'sumit, 300m2', title: 'G+1 residence',img: residence5 },
        { id: 8, category:'Bulbula, 120m2', title: 'G+2',img: residence6 },
        { id: 8, category:'90m2, Zenebe Work', title: 'G+0 Villa',img: residence7 },
      ],
    },
    {
      title: 'Restaurants',
      content: [
        { id: 9, category:'bulbula, 40/60 condominiums', title: 'Sington Coffee',img: restaurant1 },
        { id: 10, category:'Hayat, 20/80 condominiums', title: 'Sami Bar',img: restaurant2 },
      ],
    },
    {
      title: 'Shops',
      content: [
        { id: 9, category:'Edna Mall, South gate building', title: 'A+',img: shop },
        { id: 10, category:'Meskel Flower', title: 'Cosmetics Shop',img: shop1 },
        { id: 11, category:'22, Adey Ababa Stadium', title: 'Gold Shop',img: shop2 },
        { id: 12, category:'olompia, Aberus Building', title: 'Hair Salon',img: shop3 },
        { id: 13, category:'olompia infront of getu commercial', title: 'i-link',img: shop4 },
        { id: 14, category:'olompia next to getu commercial', title: 'IP-tech',img: shop5 },
        { id: 16, category:'Bole infront of Selam City Mall', title: 'Kids Shop',img: shop7 },
        { id: 17, category:'olompia next to getu commercial', title: 'Milki Tech',img: shop8 },
        { id: 19, category:'olompia, Aberus Building', title: 'Nano Aberus ICT',img: shop17 },
        { id: 20, category:'olompia next to getu commercial', title: 'Nano Getu ICT',img: shop9 },
        { id: 21, category:'olompia next to getu commercial', title: 'Phone Shop',img: shop10 },
        { id: 12, category:'Shops', title: 'Sara Velo',img: shop11 },
        { id: 12, category:'Bole infront of Selam City Mall', title: 'Soli Cosmetics',img: shop12 },
        { id: 12, category:'olompia, Aberus Building', title: 'Ultra Electronics',img: shop13 },
        { id: 12, category:'Jackros', title: 'Welldone Ceramics',img: shop15 },
        { id: 12, category:'Bole Matemia, Yeshi Building', title: 'Yeshi mens fashion',img: shop16 },
      ],
    },
    {
      title: 'Studios',
      content: [
        { id: 9, category:'Gerji', title: 'IEYA',img: studio },
        { id: 10, category:'SAr bet, Lidya building', title: 'Wirtu Media',img: studio1 },
      ],
    },
    // Add more tab data as needed
  ];

  
  const data = {
    heading: 'Title',
    subheading: 'Subtitle',
    img: 'image-url.jpg',
  };


  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveTab((prevTab) => (prevTab + 1) % tabData.length);
    }, 1000000); // Change slides every 3 seconds (adjust as needed)

    return () => clearInterval(interval);
  }, [tabData.length]);


  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  return (
    <div className='portfolio-container'>
      <h1 className='portfolio-header'>PORTFOLIO</h1>
      <div className="tab-btns">
        {tabData.map((tab, index) => (
                    <p
                    key={index}
                    onClick={() => handleTabClick(index)}
                    className={index === activeTab ? 'active' : ''}
                  >
                    {tab.title}
                  </p>
        ))}
      </div>
        {tabData.map((category, categoryIndex) => (
          <div key={categoryIndex} className="tab-content">
            {category.content.map((item, itemIndex)=>(
              <div  className={`tab ${categoryIndex === activeTab ? 'active' : ''}`}>
                <div className="item">
                <motion.img initial={{ opacity: 0, x:"150px", scale: 0.8 }}
                            whileInView={{ opacity: 1,x:"0px", scale: 1 }}
                            transition={{ duration: 0.4, type: "spring", delay: 0.2 }}
                            src={item.img} alt="" />
                </div>
               <div className="texts">
                <motion.h2 initial={{ opacity: 0, x:"150px", scale: 0.8 }}
                            whileInView={{ opacity: 1,x:"0px", scale: 1 }}
                            transition={{ duration: 0.4, type: "spring", delay: 0.2 }}>
                            {item.title}</motion.h2>
                <motion.h3 initial={{ opacity: 0, x:"150px", scale: 0.8 }}
                            whileInView={{ opacity: 1,x:"0px", scale: 1 }}
                            transition={{ duration: 0.4, type: "spring", delay: 0.2 }} >
                            {item.category}</motion.h3>
               </div>
             
                
              <p></p>
            </div>
            ))}
          </div>
        ))}
    </div>
  )
}

export default Portfolio;