import React from 'react'
import './copyright.css'
const Copyright = () => {
  return (
    <div className='copyright'>
        <p className='copyright-text'>© Copyright 2023 | Harkani Interiors. All Rights Reserved</p>
        <p className='copyright-text'>Website Designed & Developed By <span>Hewa Technologies</span></p>
    </div>
  )
}

export default Copyright