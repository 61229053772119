
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons'
import './whatwedo.css'
import work1 from '../assets/interior.png'
import work2 from '../assets/decoration.png'
import work3 from '../assets/landscape.png'
import work4 from '../assets/lighting.png'
import React, { useEffect, useRef } from 'react'
import { useInView } from 'react-intersection-observer'
import { inView, motion, useAnimation } from "framer-motion"

const Whatwedo = () => {
    // const {ref, inView} = useInView();
    // const animation = useAnimation();
    // const animation2 = useAnimation();
    // useEffect(()=>{
    //   if(inView){
    //       animation.start("visible");
    //       animation2.start("visible");
    //   }
    //   if(!inView){
    //     animation.start("hidden");
    //     animation2.start("hidden");
    //   }
  
    // }, [inView]);
    const works = [{
        img: work1,
        title: "Interior Design",
        list:[{
                id: 1,
                item: "SPACEPLANNING"
            },
            {
                id: 2,
                item: "FURNITURE LAYOUT",

            },
            {
                id: 3,
                item: "DESIGN CONSULTATION",

            },
            {
                id: 3,
                item: "WALLS",

            },
            {
                id: 2,
                item: "PARTITION",

            },
            {
                id: 2,
                item: "STAIRS",

            },
            {
                id: 2,
                item: "ENTRANCE",

            },
            {
                id: 2,
                item: "CORNER",

            },
            {
                id: 2,
                item: "BEDROOM",

            },
            {
                id: 2,
                item: "BATHROOM",

            },
            {
                id: 2,
                item: "SHOPS",

            },
            {
                id: 2,
                item: "OFFICE",

            },
            {
                id: 2,
                item: "CAFE/RESTAURANTS",

            },
            {
                id: 2,
                item: "FLOORING",

            },
            ]
    },
    {
        img: work2,
        title: "Decoration",
        list:[{
                id: 1,
                item: "Furnishing"
            },
            {
                id: 2,
                item: "Lighting",

            },
            {
                id: 3,
                item: "Accessories",

            },
            {
                id: 4,
                item: "Carpenting",

            },
            ]
    },
    {
        img: work3,
        title: "Landscape",
        list:[{
                id: 1,
                item: "Pool Side"
            },
            {
                id: 2,
                item: "Decoration",

            },
            {
                id: 3,
                item: "Balcony",

            },
            {
                id: 4,
                item: "Garden",

            },
            {
                id: 5,
                item: "Entrance",

            },
            {
                id: 6,
                item: "Car Shades",

            },
            ]
    },
    {
        img: work4,
        title: "Lighting",
        list:[{
                id: 1,
                item: "We can bring in the life to your space by complete lighting designs."
            },
            ]
    }

]
  return (
    <div className='works-container' id='works'>
        <h1 className='works-header'>What we do</h1>
        {works.map((work, index) => (
        <div  key={index} className="work-box">
            <div className="img-container">
                <motion.img 
                initial={{ opacity: 0, x:"150px", scale: 0.5 }}
                whileInView={{ opacity: 1, x:"0px", scale:1 }}
                transition={{ duration: 0.5, type: "spring", delay: 0.2 }}
                src={work.img} alt="" />   
            </div>
            <div className="work">
                <motion.h2 
                    initial={{ opacity: 0, x:"150px", scale: 0.5 }}
                    whileInView={{ opacity: 1, x:"0px", scale:1 }}
                    transition={{ duration: 0.5, type: "spring", delay: 0.2 }}               
                >{work.title}</motion.h2>
                <motion.div 
                initial={{ opacity: 0, x:"150px", scale: 0.5 }}
                whileInView={{ opacity: 1, x:"0px", scale:1 }}
                transition={{ duration: 0.5, type: "spring", delay: 0.2 }} className="list">
                        {work.list.map((item, itemIndex) => (
                            <li key={itemIndex}><FontAwesomeIcon className='fa-icon' icon={faCircleCheck} style={{color: "var(--yellow)",}}/>{item.item}</li>
                        ))}
                </motion.div>
            </div>
        </div>
        ))}
    </div>
  )
}

export default Whatwedo