
import './App.css';
import logo from'./assets/Frame.png'
import { Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Hero from './components/Hero';
import About from './components/About';
import Team from './components/Team';
import Whatwedo from './components/Whatwedo';
import Explore from './components/Explore';
import Portfolio from './pages/Portfolio';
import Blogs from './pages/Blogs';
import Home from './pages/Home';
import callBtn from '../src/assets/call.png'
import Copyright from './components/Copyright';
function App() {
  return (

    <div className="App">
        <Navbar logo={logo} />
        <Routes>
        <Route path='/' element={<Home />}></Route>
          <Route path='/portfolio'  element={<Portfolio />}></Route>
          <Route path='/blogs'  element={<Blogs />}></Route>
          <Route path='/works'  element={<Whatwedo />}></Route>
          <Route path='/about'  element={<About />}></Route>
        </Routes>
        <Footer logo={logo} />
        <Copyright />
  
        {/* <Hero />
        <About />
        <Team />
        <Whatwedo />
        <Explore /> */}
        <div className='call-us-btn'>
          
          <img src={callBtn} />
          <a className='call-us-text' href="tel:+251951108127">Call Us</a>
          </div>
    </div>
    
  );
}

export default App;
