import React, { useEffect, useRef } from 'react'
import './team.css'
import { useInView } from 'react-intersection-observer'
import { inView, motion, useAnimation } from "framer-motion"
import person1 from '../assets/kena.png'
import person2 from '../assets/wondmeneh.png'
import person3 from '../assets/yohannes.png'
import person4 from '../assets/abenet.png'
const Team = () => {
  const {ref, inView} = useInView();
  const animation = useAnimation();
  const animation2 = useAnimation();
  useEffect(()=>{
    if(inView){
        animation.start("visible");
        animation2.start("visible");
    }
    if(!inView){
      animation.start("hidden");
      animation2.start("hidden");
    }

  }, [inView]);
  return(
    <div className='team-container'>
      <motion.h2 variants={{
          hidden: { opacity: 0, x:75 },
          visible: { opacity: 1, x: 0},
        }}
  
         initial="hidden"      
         animate={animation2}
         transition={{duration: 1, delay: 0.2, type: "spring"}} className='team-header'>Meet the Team Behind</motion.h2>
      <motion.h2 variants={{
          hidden: { opacity: 0, x:75 },
          visible: { opacity: 1, x: 0},
        }}
  
         initial="hidden"      
         animate={animation2}
         transition={{duration: 1, delay: 0.3, type: "spring"}} className='team-subheader'>Harkani Interiors</motion.h2>
      <div className="team-box">
        <div ref={ref} className="persons">
          <motion.img variants={{
          hidden: { opacity: 0, x:75 },
          visible: { opacity: 1, x: 0},
        }}
  
         initial="hidden"      
         animate={animation2}
         transition={{duration: 1, delay: 0.4, type: "spring"}} src={person1} alt="" />
          <motion.img variants={{
          hidden: { opacity: 0, x:75 },
          visible: { opacity: 1, x: 0},
        }}
  
         initial="hidden"      
         animate={animation2}
         transition={{duration: 1, delay: 0.5, type: "spring"}} src={person2} alt="" />
          <motion.img variants={{
          hidden: { opacity: 0, x:75 },
          visible: { opacity: 1, x: 0},
        }}
  
         initial="hidden"      
         animate={animation2}
         transition={{duration: 1, delay: 0.6, type: "spring"}} src={person3} alt="" />
          <motion.img variants={{
          hidden: { opacity: 0, x:75 },
          visible: { opacity: 1, x: 0},
        }}
  
         initial="hidden"      
         animate={animation2}
         transition={{duration: 1, delay: 0.7, type: "spring"}} src={person4} alt="" />
        </div>
      </div>
    </div>
  )
}

export default Team