import React from 'react'
import './footer.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faXTwitter, faInstagram, faLinkedin, faTiktok, faTelegram } from '@fortawesome/free-brands-svg-icons'

const Footer = (props) => {
  return (
    <div className='footer-container' id='contact'>
        <a href="/"><img src={props.logo} alt="" className='logo'/></a>
        <div className="adress-box">
            <p>Bole Olompia, Dembel City Center,<br></br> 
                Floor 12, Room32<br></br> 
                <strong>Addis Ababa, Ethiopia</strong></p>
            <p>+251951108127 | +251994268509</p>
            <p>info@harkaniinteriors.com</p>
            <div className="social-links">
                <a href="https://www.facebook.com/harkaniinteriors" target='_blank'><FontAwesomeIcon icon={faFacebook} style={{color: "#707377", fontSize:'30px'}}/></a>
                <a href="https://www.tiktok.com/@harkaniinteriors" target='_blank'><FontAwesomeIcon icon={faTiktok} style={{color: "#707377", fontSize:'30px'}} /></a>
                <a href="https://instagram.com/harkaniinteriors" target='_blank'><FontAwesomeIcon icon={faInstagram} style={{color: "#707377", fontSize:'30px'}} /></a>
                <a href="https://www.linkedin.com/company/harkani-interiors/" target='_blank'><FontAwesomeIcon icon={faLinkedin} style={{color: "#707377", fontSize:'30px'}} /></a>            
                <a href="https://t.me/harkani_interiors" target='_blank'><FontAwesomeIcon icon={faTelegram} style={{color: "#707377", fontSize:'30px'}} /></a>            
            </div>
        </div>
        <div className="email-container">
            <form action="#">
                <div className="col-1">
                    <input type="text" name='name' id='name' placeholder='Name' />
                    <input type="email" name='email' id='email' placeholder='Email' />
                    <input type="number" name='phone' id='phone' placeholder='Phone' />
                </div>
                <div className="col-2">
                    <textarea type="text" name="message" id="" cols="30" rows="10" placeholder='Message'></textarea>
                    <input className='submit' type="submit" />
                </div>
            </form>
        </div>
    </div>
  )
}

export default Footer