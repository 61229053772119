import React from 'react'
import './explore.css'
import ArtWork from '../assets/Group.svg'
import exploreImg from '../assets/Group 7.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleChevronRight } from '@fortawesome/free-solid-svg-icons'

const Explore = () => {
  return (
    <div className='explore-container'>
        <img className='bg-explore' src={exploreImg} alt="" />
        <div className="text">
            <h4>Explore our latest works</h4>
            <a  className='arrow' href="/portfolio"><FontAwesomeIcon  icon={faCircleChevronRight} style={{color: "var(--yellow)", fontSize:'50px', float:'right'}} /></a>
        </div>
        <img className='artwork' src={ArtWork} alt="" />
    </div>
  )
}

export default Explore