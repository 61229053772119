import React, { useEffect, useRef } from 'react'
import './home.css'
import { useInView } from 'react-intersection-observer'
import { inView, motion, useAnimation } from "framer-motion"
import Hero from '../components/Hero';
import ArtWork from '../assets/Group.svg'
import About from '../components/About';
import Team from '../components/Team';
import Whatwedo from '../components/Whatwedo';
import Explore from '../components/Explore';
const Home = () => {
  const {ref, inView} = useInView();
  const animation = useAnimation();
  const animation2 = useAnimation();
  useEffect(()=>{
    if(inView){
        animation.start("visible");
        animation2.start("visible");
    }
    if(!inView){
      animation.start("hidden");
      animation2.start("hidden");
    }

  }, [inView]);
  return (
    <div className='home' ref={ref}>
      <motion.img initial={{ opacity: 0, y:"150px", scale: 0.8 }}
              whileInView={{ opacity: 1,y:"0px", scale: 1 }}
              transition={{ duration: 1.5, type: "spring", delay: 0.2 }} 
          className='bg-fixed' src={ArtWork} alt="" />
        <img className='bg-fixed nd' src={ArtWork} alt="" />
        <Hero />
        <About />
        <Team />
        <Whatwedo />
        <Explore />
        </div>
  )
}

export default Home;