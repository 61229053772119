import React, { useState } from 'react'
import './navbar.css'
import { Link } from 'react-router-dom'
import ArtWork from '../assets/Group.svg'
import openMenu from'../assets/bars-solid.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsis, faXmark } from '@fortawesome/free-solid-svg-icons'
import { motion } from "framer-motion"
import { NavLink} from 'react-router-dom';

const Navbar = (props) => {
  
    const [isOpen, setIsOpen] = useState(false)
    const [menu, setMenu] = useState("nav-links")
    const openNav = () =>{
        setIsOpen(!isOpen);
        console.log("open-nav")
    }



  return (
    <div className='nav-bar'>
        
        <a href="/"><img src={props.logo} alt="" className='logo'/></a>
        <ul className={isOpen? 'nav-links active' : 'nav-links'}>
        {isOpen? <img 
          className='navbg-fixed' src={ArtWork} alt="" /> : ''}
          
        <motion.li 
          initial={{ opacity: 0, x:"150px", scale: 0.5 }}
          onClick={openNav}
          animate={{ opacity: 1,x:"0px", scale: 1 }}
          transition={{ duration: 0.5, type: "spring", delay: 0.1 }}
          className= "nav-link"><NavLink to="/works" activeClassName="nav-link-active">Services</NavLink>
        </motion.li>
        <motion.li 
          initial={{ opacity: 0, x:"150px", scale: 0.5 }}
          onClick={openNav}
          animate={{ opacity: 1,x:"0px", scale: 1 }}
          transition={{ duration: 0.5, type: "spring", delay: 0.1 }}
          className="nav-link"><NavLink to="/portfolio" activeClassName="nav-link-active">Portfolio</NavLink>
        </motion.li>
        <motion.li 
          initial={{ opacity: 0, x:"150px", scale: 0.5 }}
          onClick={openNav}
          animate={{ opacity: 1,x:"0px", scale: 1 }}
          transition={{ duration: 0.5, type: "spring", delay: 0.1 }}
          className="nav-link"><NavLink to="/about" activeClassName="nav-link-active">About</NavLink>
        </motion.li>
        <motion.li 
          initial={{ opacity: 0, x:"150px", scale: 0.5 }}
          onClick={openNav}
          animate={{ opacity: 1,x:"0px", scale: 1 }}
          transition={{ duration: 0.5, type: "spring", delay: 0.1 }}
          className="nav-link"><NavLink to="/blogs" activeClassName="nav-link-active">Blogs</NavLink>
        </motion.li>
            {/* <li className="nav-link"><a href="#">Services</a></li>
            <li className="nav-link"><a href="#">Projects</a></li>
            <li className="nav-link"><a href="#">About</a></li>
            <li className="nav-link"><a href="#">Blogs</a></li> */}
            <button onClick={openNav}  className="nav-cta"><a href="#contact">Contact us</a></button>
        </ul>
        
        <div className="menu-icons">
             <div onClick={openNav} className={isOpen? "hamburger active" : "hamburger"}>
              <div className="bar"></div>
              <div className="bar"></div>
              <div className="bar"></div>
            </div> 
        </div>
       
    </div>
  )
}

export default Navbar