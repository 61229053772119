import React from 'react'
import { useMediaQuery } from 'react-responsive';
import './hero.css'
import { motion } from "framer-motion"
import heroImg1 from '../assets/hero-1.png'
import heroImg2 from '../assets/hero-2.png'
import heroImg3 from '../assets/hero-3.png'
import heroImg4 from '../assets/mobile-hero-1.png'
import heroImg5 from '../assets/mobile-hero-2.png'
import heroImg6 from '../assets/mobile-hero-3.png'

const Hero = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 }); // Define your breakpoint here
  const isSmallDevice = useMediaQuery({ maxWidth: 500 }); // Define your breakpoint here
  return (
    <div className="hero">
      {isMobile? (
        <div className='hero-mobile'>
          <div className="line-2">
          <motion.h2 initial={{ opacity: 0, x:"-150px", scale: 0.8 }}
              whileInView={{ opacity: 1, x:"0px", scale:1 }}
              transition={{ duration: 1, type: "spring", delay: 0.1 }}  className='hero-text-mobile'>Transforming</motion.h2>
          </div>
          <div className="line-2 my-50">
          <motion.img initial={{ opacity: 0, x:"150px", scale: 0.8 }}
               whileInView={{ opacity: 1, x:"0px", scale:1 }}
              transition={{ duration: 1.2, type: "spring", delay: 0.1 }} src={heroImg4} alt="" style={ isSmallDevice? {width: '190px'} : {width: '220px'}} />
            <motion.h2 initial={{ opacity: 0, y:"150px", scale: 0.8 }}
              whileInView={{ opacity: 1,y:"0px", scale: 1 }}
              transition={{ duration: 1.2, type: "spring", delay: 0.2 }} className='hero-text-mobile'>Spaces</motion.h2>
          </div>
          <div className="line-2 my-50">
          <motion.h2 initial={{ opacity: 0, y:"150px", scale: 0.8 }}
              whileInView={{ opacity: 1,y:"0px", scale: 1 }}
              transition={{ duration: 1.5, type: "spring", delay: 0.2 }} className='hero-text-mobile'>Inspiring</motion.h2>
          <motion.img initial={{ opacity: 0, x:"150px", scale: 0.8 }}
              whileInView={{ opacity: 1,x:"0px", scale: 1 }}
              transition={{ duration: 1.5, type: "spring", delay: 0.1 }} src={heroImg6} alt="" style={ isSmallDevice? {width: '150px'} : {width: '160px'}} />
          </div>
          <div className="line-2 my-50">
            <motion.img initial={{ opacity: 0, x:"150px", scale: 0.8 }}
                whileInView={{ opacity: 1,x:"0px", scale: 1 }}
                transition={{ duration: 2, type: "spring", delay: 0.1 }} src={heroImg5} alt="" style={ isSmallDevice? {width: '230px'} : {width: '270px'}} />
            <motion.h2 initial={{ opacity: 0, y:"150px", scale: 0.8 }}
                whileInView={{ opacity: 1,y:"0px", scale: 1 }}
                transition={{ duration: 2, type: "spring", delay: 0.2 }} className='hero-text-mobile yellow'>Lives.</motion.h2>
          </div>
      </div>

      )
    : (
      <div className='hero-container'>
          <div className="line-1">
            <motion.img initial={{ opacity: 0, x:"150px", scale: 0.8 }}
              animate={{ opacity: 1,x:"0px", scale: 1 }}
              transition={{ duration: 1.5, type: "spring", delay: 0.1 }} src={heroImg1} alt="" style={{width: '150px'}} />
            <motion.h2 initial={{ opacity: 0, x:"-150px", scale: 0.8 }}
              animate={{ opacity: 1,x:"0px", scale: 1 }}
              transition={{ duration: 1.5, type: "spring", delay: 0.1 }}  className='hero-text'>Transforming</motion.h2>
          </div>
          <div className="line-1 mt-50 reverse">
            <motion.img initial={{ opacity: 0, y:"150px", scale: 0.8 }}
              animate={{ opacity: 1,y:"0px", scale: 1 }}
              transition={{ duration: 1.5, type: "spring", delay: 0.2 }} src={heroImg2} className='w-100' alt="" />
            <motion.h2 initial={{ opacity: 0, y:"150px", scale: 0.8 }}
              animate={{ opacity: 1,y:"0px", scale: 1 }}
              transition={{ duration: 1.5, type: "spring", delay: 0.2 }} className='hero-text'>Spaces</motion.h2>
          </div>
          <div className="line-1 mt-50">
            <motion.img initial={{ opacity: 0, x:"150px", scale: 0.8 }}
              animate={{ opacity: 1,x:"0px", scale: 1 }}
              transition={{ duration: 1.5, type: "spring", delay: 0.3 }} src={heroImg3} alt="" style={{width: '150px'}} />
            <motion.h2 initial={{ opacity: 0, x:"-150px", scale: 0.8 }}
              animate={{ opacity: 1,x:"0px", scale: 1 }}
              transition={{ duration: 1.5, type: "spring", delay: 0.3 }} className='hero-text'>Inspiring <span>Lives.</span></motion.h2>
          </div>
        </div>
    )}
        
    </div>
    
  )
}

export default Hero